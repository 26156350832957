import React from 'react'
import * as S from './styles'

const DecorativeSquare = ({
  top,
  left,
  size,
  radius,
  color = '#000',
  opacity,
  rotation = 0
}) => {
  return (
    <S.DecorativeSquareContainer
      top={top}
      left={left}
      size={size}
      radius={radius}
      color={color}
      opacity={opacity}
      rotation={rotation}
    ></S.DecorativeSquareContainer>
  )
}

export default DecorativeSquare
