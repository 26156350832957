import React, { useEffect, useState } from 'react'
import * as S from '@/styles/pages/404'

import DecorativeSquare from '@/components/DecorativeSquare'

import { ASSET_PATHS } from '@/services/paths'

import { useTranslation } from 'react-i18next'
import Image from 'next/image'

const Page404 = () => {
  const [altLogo, setAltLogo] = useState('')
  const [titlesPageNotFound, setTitlesPageNotFound] = useState('')
  const [subtitlesPageNotFound, setSubtitlesPageNotFound] = useState('')

  const { t } = useTranslation()

  useEffect(() => {
    setAltLogo(t('alts.logo'))
    setTitlesPageNotFound(t('titles.pageNotFound'))
    setSubtitlesPageNotFound(t('subtitles.pageNotFound'))
  }, [t])

  return (
    <S.Page404Container>
      <DecorativeSquare
        top="70px"
        left="35px"
        size="185px"
        radius="25px"
        color="var(--blue2)"
        opacity={0.1}
        rotation={26}
      />
      <DecorativeSquare
        top="230px"
        left="25px"
        size="100px"
        radius="15px"
        color="var(--blue2)"
        opacity={0.1}
        rotation={26}
      />
      <DecorativeSquare
        top="calc(100% + 20px)"
        left="calc(100% - 85px)"
        size="185px"
        radius="25px"
        color="var(--blue2)"
        opacity={0.1}
        rotation={26}
      />
      <Image src={ASSET_PATHS.logo} alt={altLogo} width={300} height={107} />
      <h1>404</h1>
      <h2>{titlesPageNotFound}</h2>
      <h3>{subtitlesPageNotFound}</h3>
    </S.Page404Container>
  )
}

export default Page404
