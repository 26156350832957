import styled from 'styled-components'

export const Page404Container = styled.header`
  position: relative;
  width: 100vw;
  height: 100vh;
  padding: 3rem;
  text-align: center;
  color: var(--grey2);

  & > span {
    width: 7% !important;
  }

  h1 {
    font-size: 144px;
    font-weight: 700;
    letter-spacing: 1.5rem;
    margin-top: 6rem;
    color: var(--blue2);
    text-shadow: 14px 0 0 var(--blue4);
  }

  h2 {
    font-size: 30px;
    margin-top: 3rem;
  }

  h3 {
    font-size: 16px;
    font-weight: 400;
    margin-top: 1.5rem;
  }

  @media only screen and (max-width: 500px) {
    //MOBILE L

    padding: 3rem 1rem;

    & > span {
      width: 35% !important;
    }

    h3 {
      margin-top: 3rem;
    }
  }

  @media only screen and (max-width: 365px) {
    //MOBILE S

    h1 {
      font-size: 130px;
    }

    h2 {
      font-size: 28px;
    }

    h3 {
      font-size: 14px;
    }
  }
`
