import styled from 'styled-components'

export const DecorativeSquareContainer = styled.div`
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.left};
  width: ${props => props.size};
  height: ${props => props.size};
  border-radius: ${props => props.radius};
  background: ${props => props.color};
  opacity: ${props => props.opacity};
  transform: rotate(${props => props.rotation}deg) translate(-50%, -50%);
`
